var columns = [{
  title: "任务ID",
  dataIndex: "id",
  key: "id"
}, {
  title: "变更时间",
  dataIndex: "updatetime",
  key: "updatetime",
  width: 180,
  sorter: true,
  sortOrder: 'descend',
  scopedSlots: {
    customRender: "updatetime"
  }
}, {
  title: "任务类型",
  dataIndex: "tasktype",
  key: "tasktype"
}, {
  title: "砖块类型",
  dataIndex: "brickstype",
  key: "brickstype",
  scopedSlots: {
    customRender: "brickstype"
  }
}, {
  title: "砖块变更量",
  dataIndex: "amount",
  key: "amount"
}, {
  title: "用户",
  dataIndex: "username",
  key: "username",
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "结算状态",
  dataIndex: "status",
  key: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "备注",
  dataIndex: "remarks",
  key: "remarks",
  scopedSlots: {
    customRender: "remarks"
  }
}, {
  title: "变更人",
  dataIndex: "createbyname",
  key: "createbyname",
  scopedSlots: {
    customRender: "createbyname"
  }
}];
export { columns };