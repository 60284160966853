import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["bricksettle:btn:updatebricks"],
            expression: "['bricksettle:btn:updatebricks']"
          }],
          staticStyle: {
            "margin-left": "20px"
          },
          attrs: {
            type: "primary",
            ghost: "",
            size: "large"
          },
          on: {
            click: function click() {
              return _vm.visible = true;
            }
          }
        }, [_vm._v("砖块变更")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading,
      scroll: {
        x: "1472px"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "updatetime",
      fn: function fn(updatetime) {
        return [_vm._v(" " + _vm._s(updatetime ? updatetime : "---") + " ")];
      }
    }, {
      key: "brickstype",
      fn: function fn(brickstype) {
        return [brickstype == 1 ? _c("span", [_vm._v("普通")]) : brickstype == 2 ? _c("span", [_vm._v("银砖")]) : brickstype == 3 ? _c("span", [_vm._v("金砖")]) : _c("span", [_vm._v('"---"')])];
      }
    }, {
      key: "username",
      fn: function fn(username, _ref) {
        var uid = _ref.uid;
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: uid,
            expression: "uid"
          }],
          staticStyle: {
            cursor: "pointer"
          }
        }, [_vm._v(_vm._s(uid))])]), _c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          }
        }, [_vm._v(_vm._s(username || "---"))])], 2)];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [status == 0 ? _c("span", {
          staticStyle: {
            color: "#6fc545"
          }
        }, [_vm._v(" 待结算 ")]) : status == 1 ? _c("span", {
          staticStyle: {
            color: "blue"
          }
        }, [_vm._v(" 已结算 ")]) : _c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(" 已失效 ")])];
      }
    }, {
      key: "remarks",
      fn: function fn(remarks) {
        return [_vm._v(" " + _vm._s(remarks ? remarks : "---") + " ")];
      }
    }, {
      key: "createbyname",
      fn: function fn(createbyname) {
        return [_vm._v(" " + _vm._s(createbyname ? createbyname : "---") + " ")];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "砖块变更",
      width: 700,
      confirmLoading: _vm.loading2
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-col": {
        span: 4
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "变更类型",
      prop: "correcttype"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.onRadioChange
    },
    model: {
      value: _vm.form.correcttype,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "correcttype", $$v);
      },
      expression: "form.correcttype"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 补发 ")]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 扣除 ")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "变更用户",
      prop: "userids"
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入用户ID，非昵称，多个一行一个"
    },
    model: {
      value: _vm.form.userids,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userids", $$v);
      },
      expression: "form.userids"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "任务类型",
      prop: "type"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择任务类型"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.options.tasktype2, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        disabled: item.disabled
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "变更数值",
      prop: "amount"
    }
  }, [_c("a-input-number", {
    attrs: {
      min: 1,
      step: 1,
      precision: 0,
      placeholder: "请输入变更数值"
    },
    model: {
      value: _vm.form.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "amount", $$v);
      },
      expression: "form.amount"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "砖块类型",
      prop: "brickstype"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择砖块类型"
    },
    model: {
      value: _vm.form.brickstype,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "brickstype", $$v);
      },
      expression: "form.brickstype"
    }
  }, _vm._l(_vm.options.bricktype1, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "备注",
      prop: "remarks"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择备注模板"
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.remarksTemp,
      callback: function callback($$v) {
        _vm.remarksTemp = $$v;
      },
      expression: "remarksTemp"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: "account behavior violation"
    }
  }, [_vm._v(" account behavior violation ")]), _c("a-select-option", {
    attrs: {
      value: "account data anomaly"
    }
  }, [_vm._v(" account data anomaly ")]), _c("a-select-option", {
    attrs: {
      value: "system anomaly"
    }
  }, [_vm._v(" system anomaly ")]), _c("a-select-option", {
    attrs: {
      value: "others"
    }
  }, [_vm._v(" others ")])], 1), _c("a-input", {
    attrs: {
      type: "textarea",
      maxLength: 120
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };