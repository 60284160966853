var searchKeys = [{
  key: "ids",
  label: "任务ID",
  placeholder: "任务ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "tasktype",
  label: "任务类型",
  required: false,
  rules: [],
  select: true,
  initialValue: undefined,
  placeholder: "请选择任务类型"
}, {
  key: "bricktype",
  label: "砖块类型",
  required: false,
  rules: [],
  select: true,
  initialValue: undefined,
  placeholder: "请选择砖块类型"
}, {
  key: "userids",
  label: "用户ID",
  placeholder: "用户ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "correctusername",
  label: "变更人username",
  placeholder: "变更人username",
  required: false,
  rules: [],
  input: true,
  trim: false
}, {
  key: "status",
  label: "状态",
  required: false,
  rules: [],
  select: true,
  initialValue: undefined,
  placeholder: "请选择状态"
}, {
  key: "date",
  label: "变更时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };